<template>
  <footer class="footer">
    <div class="footer-content">
      <p>&copy; 2023 Ekkoc. All rights reserved.</p>
      <div class="social-links">
        <a href="#" class="social-link">Facebook</a>
        <a href="#" class="social-link">Twitter</a>
        <a href="#" class="social-link">LinkedIn</a>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'AppFooter'
}
</script>

<style scoped>
.footer {
  background-color: #1a1a1a;
  color: #fff;
  padding: 0.5rem 1rem;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.social-links {
  display: flex;
  gap: 1rem;
}

.social-link {
  color: #fff;
  text-decoration: none;
  transition: color 0.3s ease;
}

.social-link:hover {
  color: #00ffff;
}

@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    gap: 1rem;
  }
}
</style>