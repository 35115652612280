<template>
  <nav class="navbar">
    <div class="navbar-brand">Ekkoc</div>
    <div class="navbar-menu">
      <router-link to="/" class="navbar-item">Home</router-link>
      <router-link to="/about" class="navbar-item">About</router-link>
      <router-link to="/services" class="navbar-item">Services</router-link>
      <router-link to="/contact" class="navbar-item">Contact</router-link>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'AppNavbar'
}
</script>

<style scoped>
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  background-color: #1a1a1a;
  color: #fff;
}

.navbar-brand {
  font-size: 1.5rem;
  font-weight: bold;
}

.navbar-menu {
  display: flex;
  gap: 1rem;
}

.navbar-item {
  color: #fff;
  text-decoration: none;
  transition: color 0.3s ease;
}

.navbar-item:hover {
  color: #00ffff;
}

@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
  }
  
  .navbar-menu {
    margin-top: 1rem;
  }
}
</style>