import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home" }
const _hoisted_2 = { class: "scene-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HelloWorld = _resolveComponent("HelloWorld")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("h1", null, "Welcome to Ekkoc", -1)),
    _cache[1] || (_cache[1] = _createElementVNode("p", null, "Explore the future of technology with us.", -1)),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_HelloWorld)
    ])
  ]))
}