<template>
  <div class="home">
    <h1>Welcome to Ekkoc</h1>
    <p>Explore the future of technology with us.</p>
    <div class="scene-container">
      <HelloWorld />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import HelloWorld from '@/components/HelloWorld.vue';

export default defineComponent({
  name: 'HomePage',
  components: {
    HelloWorld
  }
});
</script>

<style scoped>
.home {
  text-align: center;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  height: 100%;
}

h1 {
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
  color: #00ffff;
}

p {
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.scene-container {
  flex: 1;
  min-height: 0;
}
</style>