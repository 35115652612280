<template>
  <div id="scene-container"></div>
</template>

<script lang="ts">
import { defineComponent, onMounted, shallowRef, markRaw } from 'vue';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

export default defineComponent({
  name: 'HelloWorld',
  setup() {
    const scene = shallowRef<THREE.Scene | null>(null);
    const camera = shallowRef<THREE.PerspectiveCamera | null>(null);
    const renderer = shallowRef<THREE.WebGLRenderer | null>(null);

    const initScene = () => {
      scene.value = new THREE.Scene();
      scene.value.background = new THREE.Color(0x111111);

      camera.value = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
      renderer.value = new THREE.WebGLRenderer({ antialias: true });

      renderer.value.setSize(window.innerWidth, window.innerHeight);
      document.getElementById('scene-container')?.appendChild(renderer.value.domElement);

      camera.value.position.set(5, 5, 5);
      camera.value.lookAt(scene.value.position);

      const geometry = new THREE.IcosahedronGeometry(2, 1);
      const material = new THREE.MeshPhongMaterial({ 
        color: 0xcccccc,
        shininess: 100,
        specular: 0x111111
      });
      const icosahedron = markRaw(new THREE.Mesh(geometry, material));
      scene.value.add(icosahedron);

      const ambientLight = new THREE.AmbientLight(0x404040);
      scene.value.add(ambientLight);

      const pointLight = new THREE.PointLight(0xffffff, 1, 100);
      pointLight.position.set(10, 10, 10);
      scene.value.add(pointLight);

      const gridHelper = new THREE.GridHelper(20, 20, 0x444444, 0x444444);
      scene.value.add(gridHelper);

      const controls = new OrbitControls(camera.value, renderer.value.domElement);
      controls.enableDamping = true;
      controls.dampingFactor = 0.05;

      const animate = () => {
        requestAnimationFrame(animate);
        icosahedron.rotation.x += 0.005;
        icosahedron.rotation.y += 0.005;
        controls.update();
        if (renderer.value && scene.value && camera.value) {
          renderer.value.render(scene.value, camera.value);
        }
      };

      animate();

      window.addEventListener('resize', onWindowResize, false);
    };

    const onWindowResize = () => {
      if (camera.value && renderer.value) {
        camera.value.aspect = window.innerWidth / window.innerHeight;
        camera.value.updateProjectionMatrix();
        renderer.value.setSize(window.innerWidth, window.innerHeight);
      }
    };

    onMounted(() => {
      initScene();
    });

    return { scene, camera, renderer };
  }
});
</script>

<style scoped>
#scene-container {
  width: 100%;
  height: 100%;
  background-color: #111111;
}
</style>
